<template>
  <div class="content-box">
    <div class="submit-btn anima" @click="downApp">下载APP</div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getAppDown } from "@/api/registeredAgent";
import { shareConfig } from "@/utils/share.js";

export default {
  data() {
    return {
      appLogoUrl: "",
      appDownloadUrl: "",
      appName: "",
      title: "<收付通>下载",
      desc: "稳、收、快、付！立即下载App开始使用吧！",
      imgUrl: "https://oss.zfshoufutong.com/image/ed/92/56/33/d1aeda423d914fe9bc2c16543a6789c5.png", //商户logo
    };
  },
  mounted() {
    shareConfig(this.title, this.desc, this.imgUrl);
  },
  methods: {
    /* 判断用户手机为安卓还是iphone */
    checkPhone() {
      // console.log('navigator', navigator)
      let self = this;
      let agent = navigator.userAgent || navigator.vendor || window.opera;
      if (agent != null) {
        let agentName = agent.toLowerCase();
        if (/android/i.test(agentName)) {
          self.isAndroid = true;
          console.log("安卓机", self.isAndroid);
          window.location.href = `https://api.zfshoufutong.com/api/resource/update/packages/package?appType=2`;
          // let params = { appType: 1 };
          // getAppDown(params)
          //   .then((res) => {
          //     window.location.href = `http://umfpay.zfmgr.top/api/resource/update/packages/package?appType=2`;
          //   })
          //   .catch((err) => {
          //     Toast({
          //       message: err.message,
          //       icon: "cross",
          //     });
          //   });
        } else if (/iphone/i.test(agentName)) {
          self.isIOS = true;
          console.log("苹果机", self.isIOS);
          // Toast("暂未开放，敬请期待");
          window.location.href = `https://itunes.apple.com/cn/app/1622543742`;
        }
      }
    },
    //点击下载应用
    downApp() {
      this.checkPhone();
    },
  },
  beforeCreate() {
    this.$nextTick(() => {
      document.body.setAttribute("style", "background-color:#fff");
    });
  },
  //实例销毁之前钩子--移除body 标签的属性style
  beforeDestroy() {
    document.body.removeAttribute("style");
  },
};
</script>
<style lang="less" scoped>
.content-box {
  position: absolute;
  min-height: 110vh;
  width: 100%;
  height: 100%;
  background: #fe5732 url("../../assets/registeredAgent/mer-bg.png") no-repeat;
  background-size: 100%;
}
.submit-btn {
  position: fixed;
  z-index: 1;
  // top: 1183px;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 620px;
  height: 92px;
  line-height: 92px;
  text-align: center;
  background: linear-gradient(270deg, #ff2b2b 0%, #ffd031 100%);
  box-shadow: 0px 3px 8px 1px rgba(236, 119, 119, 0.5);
  border-radius: 20px;
  font-size: 34px;
  font-weight: bold;
  color: #ffffff;
}
.anima {
  animation-name: downBbut; // 动画名称
  animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
  animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
  animation-delay: 0s; // 动画延迟时间
  animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
  animation-duration: 1.5s; // 动画完成时间
}

@keyframes downBbut {
  0% {
    transform: translate(-50%, 0) scale(1);
  }
  25% {
    transform: translate(-50%, 0) scale(0.9);
  }
  50% {
    transform: translate(-50%, 0) scale(0.85);
  }
  75% {
    transform: translate(-50%, 0) scale(0.9);
  }
  100% {
    transform: translate(-50%, 0) scale(1);
  }
}
</style>
